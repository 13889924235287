<template>
  <div class="m-3 p-3 surface-card border-round shadow-2 flex-auto">
    <div class="grid">
      <div class="col-12">
        <h1>Mailings</h1>
        <div class="flex">
          <Button class="mx-2" label="Clear" @click="clearSelectedMailings" />
          <MultiSelect
            v-model="selectedMailings"
            :options="mailings"
            option-label="formatted_description"
            option-value="id"
            placeholder="Select Mailings"
            class="mx-1"
            style="max-width: 500px"
          />
          <Button class="mx-2" label="Sync" @click="syncMailings" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignCycles from "@/models/CampaignCycles";
import Mailing from "@/models/Mailing";
import axios from "axios";
export default {
  data() {
    return {
      mailings: [],
      selectedMailings: [],
    };
  },
  methods: {
    clearSelectedMailings() {
      this.selectedMailings = [];
    },
    syncMailings() {
      axios.post("campaign-cycles/sync-mailings", {
        campaign_id: 1,
        campaign_cycle_id: 2,
        mailing_ids: this.selectedMailings,
      });
      return 1;
    },
  },
  mounted() {
    Mailing.where("campaign_id", 1)
      .limit(50)
      .get()
      .then((res) => {
        this.mailings = res.data;
      });
  },
};
</script>
